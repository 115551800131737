import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  enableWorkAddressInOnboarding?: boolean;
  amplitude: { apiKey: string };
  driveLinks: {
    communicationKitUrl: string;
    eulaUrl: string;
    flyersUrl: string;
  };
  faqLinks: {
    challengeCreation: string;
    fmd: string;
    home: string;
    homeWorkJourneys: string;
    homeWorkJourneys2: string;
    membersInvitation: string;
  };
  pipedriveFormUrl: string;
} = {
  ...defaultEnvironment,
  enableWorkAddressInOnboarding: false,
  source: 'geovelo_enterprise',
  apiKey: 'aad6342a-d577-4602-a9e2-a70397aa4d25',
  amplitude: { apiKey: '584fa9dd01a566e69bc4bb6c746a5f81' },
  crisp: { websiteId: 'bcf6718d-9aca-43e1-8fdb-cae66116b888' },
  faqLinks: {
    home: 'https://faq.geovelo-entreprise.com/fr/',
    homeWorkJourneys:
      'https://faq.geovelo-entreprise.com/fr/article/comment-sont-pris-en-compte-les-trajets-domicile-travail-11rwv4y/',
    homeWorkJourneys2:
      'https://faq.geovelo-entreprise.com/fr/article/a-quoi-servent-les-trajets-domicile-travail-ou-velotaf-s07907/',
    fmd: 'https://faq.geovelo-entreprise.com/fr/category/forfait-mobilite-durable-1tymtyd/',
    challengeCreation:
      'https://faq.geovelo-entreprise.com/fr/category/challenges-et-animation-1v80g8m/',
    membersInvitation:
      'https://faq.geovelo-entreprise.com/fr/category/inviter-mes-employes-3py5ba/',
  },
  pipedriveFormUrl: 'https://share-eu1.hsforms.com/13cJg9bq5RaOpJQ9ji1B91A2e5wt5',
  driveLinks: {
    communicationKitUrl:
      'https://faq.geovelo-entreprise.com/fr/article/les-ressources-pour-comprendre-et-promouvoir-geovelo-entreprise-1mpmwyx/?bust=1738849818427',
    eulaUrl: 'https://drive.google.com/file/d/1LlGt1JXlEOuuijfVMUnQsAvPQNdNpA8-/view',
    flyersUrl: 'https://drive.google.com/drive/u/0/folders/1yFdd3fwhkRT_Z-a1-gK1sMuwnbcjakyp',
  },
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
  };
}

export const environment = _environment;
