import {
  CommutingTrip,
  Employee,
  Event,
  FMDConfig,
  Geogroup,
  Partner,
  TEmployeeTransportHabits,
  TMobilitySurvey,
  TPartnerContract,
  TStripeProduct,
  TUserType,
  User,
  UserGeogroup,
  UserPlace,
  companyContractCodes,
} from '@geovelo-frontends/commons';
import { MutableRefObject, createContext, createRef } from 'react';

export type TPermissionKey =
  | 'customChallengesEnabled'
  | 'jerseysEnabled'
  | 'mobilitySurveyEnabled'
  | 'mobilitySurveyResponsesReadEnabled'
  | 'newsPostsCreationAndUpdateEnabled'
  | 'accessibilityEnabled'
  | 'advancedStatsEnabled'
  | 'fmdEnabled'
  | 'sitesEnabled'
  | 'teamsEnabled';

export const offers = [...companyContractCodes] as const;

export const _offers: string[] = [...offers];

export type TOffer = (typeof offers)[number];

export type TPermissions = { [key in TPermissionKey]: boolean };

export const offersMap: {
  [key in TOffer]: { key: string; label: string; permissions: TPermissions };
} = {
  'geovelo-entreprise-premium': {
    key: 'premium',
    label: 'Offre Premium\u00a0💎',
    permissions: {
      customChallengesEnabled: true,
      jerseysEnabled: true,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: true,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: true,
      fmdEnabled: true,
      sitesEnabled: true,
      teamsEnabled: true,
    },
  },
  'geovelo-entreprise-free': {
    key: 'free',
    label: 'Offre Gratuite\u00a0✅',
    permissions: {
      customChallengesEnabled: false,
      jerseysEnabled: false,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: false,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: false,
      fmdEnabled: false,
      sitesEnabled: true,
      teamsEnabled: false,
    },
  },
  'geovelo-entreprise-standard': {
    key: 'standard',
    label: 'Offre Standard\u00a0⭐️',
    permissions: {
      customChallengesEnabled: true,
      jerseysEnabled: true,
      mobilitySurveyEnabled: true,
      mobilitySurveyResponsesReadEnabled: true,
      newsPostsCreationAndUpdateEnabled: true,
      accessibilityEnabled: true,
      advancedStatsEnabled: true,
      fmdEnabled: false,
      sitesEnabled: true,
      teamsEnabled: true,
    },
  },
};

export const getStartedEmployeeSteps = ['work', 'site', 'profilePicture', 'team'] as const;

export type TGetStartedEmployeeSteps = (typeof getStartedEmployeeSteps)[number];

export interface IUserContext {
  commutingTrips?: CommutingTrip[];
  current?: User | null;
  employee?: Employee | null;
  employees?: Array<{ employee: Employee; partner: Partner }>;
  getStartedProgression?: {
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedEmployeeSteps]?: boolean };
    stepsDone: { [key in TGetStartedEmployeeSteps]: boolean };
  };
  home?: UserPlace | null;
  isRegister?: boolean;
  site?: UserGeogroup | null;
  team?: UserGeogroup | null;
  transportHabits?: TEmployeeTransportHabits | null;
  works?: UserPlace[];
}

export const getStartedAdminSteps = ['accountConfig', 'firstChallenge', 'discoverOffers'] as const;

export type TGetStartedAdminSteps = (typeof getStartedAdminSteps)[number];

export type TGetStartedAdminSubsteps = {
  accountConfig: 'logo' | 'invitations';
  firstChallenge: 'firstChallenge';
  discoverOffers: 'discoverOffers';
};

export type TGetStartedAdminStepProgression<TSubsteps> = {
  allDone: boolean;
  doneCount: number;
  substeps: Array<{ done: boolean; key: TSubsteps }>;
};

export type TGetStartedAdminProgression = {
  allDone: boolean;
  doneCount: number;
  steps: {
    [key in TGetStartedAdminSteps]: TGetStartedAdminStepProgression<TGetStartedAdminSubsteps[key]>;
  };
};

export interface IPartnerContext {
  firstMembersAddedDialogOpenRef?: MutableRefObject<boolean>;
  current?: Partner | null;
  contract?: TPartnerContract | null;
  teams?: Geogroup[] | null;
  employeesCount?: number;
  fmdConfig?: FMDConfig | null;
  getStartedProgression?: TGetStartedAdminProgression;
  getStartedSkippedSteps: {
    [key in TGetStartedAdminSteps]?: { [subkey in TGetStartedAdminSubsteps[key]]?: boolean };
  };
  geogroup?: Geogroup | null;
  inactiveEmployees?: Employee[] | null;
  invitationCode?: string | null;
  mobilitySurveys?: TMobilitySurvey[];
  permissions: { [key in TPermissionKey]: boolean };
  sites?: Geogroup[] | null;
  availableEvents?: { current: Event[]; future: Event[] } | null;
}

type TStripeContext = {
  products: TStripeProduct[] | undefined;
};

interface IAppActions {
  getAvailableEvents: (partner: Partner, geogroup: Geogroup) => void;
  getPartnerEmployees: (partner: Partner) => void;
  selectCompany: (employee: Employee, partner: Partner) => void;
  setCurrentUser: (user?: User | null) => void;
  setCurrentPartner: (partner?: Partner | null) => void;
  setPartnerAvailableEvents: (events?: { current: Event[]; future: Event[] } | null) => void;
  setPartnerContract: (contract?: TPartnerContract | null) => void;
  setPartnerEmployeesCount: (count?: number) => void;
  setPartnerFMDConfig: (fmdConfig?: FMDConfig | null) => void;
  setPartnerGetStartedSkippedSteps: (steps: {
    [key in TGetStartedAdminSteps]?: { [subkey in TGetStartedAdminSubsteps[key]]?: boolean };
  }) => void;
  setPartnerGeogroup: (geogroup?: Geogroup | null) => void;
  setPartnerHasChallenges: (hasChallenge?: boolean) => void;
  setPartnerInactiveEmployees: (employees?: Employee[] | null) => void;
  setPartnerInvitationCode: (code?: string | null) => void;
  setPartnerMobilitySurveys: (surveys?: TMobilitySurvey[]) => void;
  setPartnerPermissions: (permissions: { [key in TPermissionKey]: boolean }) => void;
  setPartnerSites: (sites?: Geogroup[] | null) => void;
  setPartnerTeams: (teams?: Geogroup[] | null) => void;
  setStripeProducts: (products: TStripeProduct[]) => void;
  setUserCommutingTrips: (trips?: CommutingTrip[]) => void;
  setUserEmployee: (employee?: Employee | null) => void;
  setUserEmployees: (employees?: Array<{ employee: Employee; partner: Partner }>) => void;
  setUserHome: (home?: UserPlace | null) => void;
  setUserIsRegister: (isRegister?: boolean) => void;
  setUserSite: (site?: UserGeogroup | null) => void;
  setUserTeam: (team?: UserGeogroup | null) => void;
  setUserTransportHabits: (habits?: TEmployeeTransportHabits | null) => void;
  setUserWorks: (works?: UserPlace[]) => void;
}

interface IAppContext {
  actions: IAppActions;
  history: MutableRefObject<string[] | null>;
  user: IUserContext;
  partner: IPartnerContext;
  stripe: TStripeContext;
}

export const AppContext = createContext<IAppContext>({
  history: createRef(),
  user: {},
  partner: {
    permissions: offersMap['geovelo-entreprise-free'].permissions,
    getStartedSkippedSteps: {},
  },
  stripe: { products: undefined },
  actions: {
    getAvailableEvents: () => undefined,
    setStripeProducts: () => undefined,
    setCurrentUser: () => undefined,
    selectCompany: () => undefined,
    setCurrentPartner: () => undefined,
    setPartnerContract: () => undefined,
    setPartnerInvitationCode: () => undefined,
    setPartnerGeogroup: () => undefined,
    setPartnerTeams: () => undefined,
    setPartnerSites: () => undefined,
    getPartnerEmployees: () => undefined,
    setPartnerEmployeesCount: () => undefined,
    setPartnerInactiveEmployees: () => undefined,
    setPartnerFMDConfig: () => undefined,
    setPartnerMobilitySurveys: () => undefined,
    setPartnerPermissions: () => undefined,
    setPartnerHasChallenges: () => undefined,
    setPartnerAvailableEvents: () => undefined,
    setPartnerGetStartedSkippedSteps: () => undefined,
    setUserIsRegister: () => undefined,
    setUserEmployee: () => undefined,
    setUserEmployees: () => undefined,
    setUserTeam: () => undefined,
    setUserSite: () => undefined,
    setUserHome: () => undefined,
    setUserWorks: () => undefined,
    setUserCommutingTrips: () => undefined,
    setUserTransportHabits: () => undefined,
  },
});

export type { TUserType };
